export const employeeDetailValidation = {
    employeeName: {
        presence: {
            allowEmpty: false,
            message: "^Nama karyawan wajib diisi"
        },
        type: 'string'
    },
    nip: {
        numericality: {
            message: "^NIP harus berupa angka"
        },
        presence: {
            allowEmpty: false,
            message: "^NIP karyawan wajib diisi"
        },
    },
    email: {
        email: {
            message: "^Email yang anda masukkan tidak valid"
        },
        presence: {
            allowEmpty: false,
            message: "^Email karyawan wajib diisi"
        },
    },
    selectDivision: {
        presence: {
            allowEmpty: false,
            message: "^Pilih salah satu divisi"
        },
        type: "string"
    }
}