import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Button, Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import {useDispatch, useSelector} from "react-redux";
import {
    EMPLOYEE_DETAIL_UPDATE,
    PRODUCT_REQUEST_ERROR,
    PRODUCT_REQUEST_UPDATE
} from "../../reducers/employee-request.reducer";
import {getEmployeeOrder, getProductList} from "../../api/request-product.api";

const ProductSelectionForm = () => {
    const {
        productRequestByEmployee, totalQuotaLeft, productsRequested,
        selectionError, quantityError, employeeDetail, employeeStatus
    } = useSelector(state => state.employeeRequestReducer)
    const [selectionValue, setSelectionValue] = useState(null)
    const [openConfirmationDialog, setConfirmationDialog] = useState(false)
    const [productQuantity, setProductQuantity] = useState(1)
    const [productList, setProductList] = useState([])
    const dispatch = useDispatch()
    const defaultProps = {
        options: productList,
        getOptionLabel: (product) => product.productName,
    };
    const today = new Date()
    const requestStartDate = new Date(today.getFullYear(),
        today.getMonth(), 1)
        .toLocaleString("en-US",{month:"2-digit", day:"2-digit", year:"numeric"})
    const [isLoadingProductList, setIsLoadingProductList] = useState(false)

    useEffect(() => {
        setIsLoadingProductList(true)
        getProductList()
            .then(result => {
                const productList = result.filter(item => item.isAvailable === "TRUE")
                setProductList(productList)
                setIsLoadingProductList(false)
            })
            .catch(error => {
                setIsLoadingProductList(false)
                alert(error)
            })
        getEmployeeOrder(employeeStatus.orderData, employeeDetail.Email, `${requestStartDate}`)
            .then(result => {
                const totalProductRequested = _.sumBy(result, order => Number(order.JumlahProduk))
                const totalQuotaLeft = Number(employeeStatus.productQuota) - Number(totalProductRequested)
                dispatch({type: EMPLOYEE_DETAIL_UPDATE, payload: {totalQuotaLeft, productsRequested: result}})
            })
            .catch(error => {
                alert(error)
            })
    }, [])

    const addProductRequest = (selectionValue, addConfirmed) => {
        if (!!selectionValue) {
            if (productQuantity < 1) {
                dispatch({type: PRODUCT_REQUEST_ERROR, payload: {quantityError: "Min. 1"}})
                return
            }
            const indexOfProduct = _.findIndex(productRequestByEmployee, {SKUNumber: selectionValue.SKUNumber})
            let productRequest = {}
            let newRequestProduct = []

            if(paretoProductInvalid(selectionValue)) return;

            if (indexOfProduct < 0) {
                productRequest = {...selectionValue, quantity: productQuantity}
                newRequestProduct = [...productRequestByEmployee, productRequest]
                dispatch({type: PRODUCT_REQUEST_UPDATE, payload: newRequestProduct})
            } else if (addConfirmed) {
                let newProductQty = Number(productRequestByEmployee[indexOfProduct].quantity) + Number(productQuantity)
                productRequest = {
                    ...productRequestByEmployee[indexOfProduct],
                    quantity: newProductQty <= 6 ? newProductQty : 6
                }
                newRequestProduct = _.filter(productRequestByEmployee, (item, index) => index !== indexOfProduct);
                setConfirmationDialog(false)
                dispatch({type: PRODUCT_REQUEST_UPDATE, payload: [...newRequestProduct, productRequest]})
            } else {
                setConfirmationDialog(true)
            }
        } else {
            dispatch({type: PRODUCT_REQUEST_ERROR, payload: {selectionError: "Pilih salah satu produk."}})
        }
    }

    const paretoProductInvalid = (selectionValue) => {
        if(selectionValue.paretoNumber > 0) {
            const indexOfProductRequested = _.findIndex(productsRequested, {SKUNumber: selectionValue.SKUNumber})
            const indexOfProductNewRequest=_.findIndex(productRequestByEmployee, {SKUNumber: selectionValue.SKUNumber})
            const newQuantityRequest = indexOfProductNewRequest >= 0 ?
                Number(productRequestByEmployee[indexOfProductNewRequest].quantity) : 0
            const quantityRequested = indexOfProductRequested >= 0 ?
                Number(productsRequested[indexOfProductRequested].JumlahProduk) : 0

            if((quantityRequested + newQuantityRequest + Number(productQuantity) >
                Number(selectionValue.paretoNumber))){
                dispatch({
                    type: PRODUCT_REQUEST_ERROR,
                    payload: {selectionError: `Pengambilan produk terbatas max.${selectionValue.paretoNumber} pcs/bulan. 
                    Anda sudah mengambil ${quantityRequested} pada order sebelumnya.`}})
                return true
            }
        }
    }

    const onQuantityChange = (quantity) => {
        if (quantity <= 6) {
            dispatch({type: PRODUCT_REQUEST_ERROR, payload: {quantityError: ""}})
            setProductQuantity(quantity)
        } else {
            dispatch({type: PRODUCT_REQUEST_ERROR, payload: {quantityError: "Max. 6"}})
        }
    }

    const deleteProductRequest = (productSelected) => {
        const newRequestProduct = _.remove(productRequestByEmployee,
            (item) => item.SKUNumber !== productSelected.SKUNumber)
        dispatch({type: PRODUCT_REQUEST_UPDATE, payload: newRequestProduct})
    }

    return (
        <Container sx={{backgroundColor: '#F1F1F1', pb: 2, pt: 2}}>
            <Typography variant="subtitle1" gutterBottom>
                Pilihan Produk
            </Typography>
            <Divider/>
            <Grid container spacing={3} sx={{p: 2}}>
                <Grid item xs={8}>
                    <Autocomplete
                        {...defaultProps}
                        id="clear-on-escape"
                        value={selectionValue}
                        autoComplete
                        isOptionEqualToValue={() => (productList, selectionValue)}
                        onChange={(event, value) => {
                            dispatch({type: PRODUCT_REQUEST_ERROR, payload: {selectionError: "", quantityError: ""}})
                            setSelectionValue(value)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Nama Produk" variant="standard"
                                       error={!!selectionError}
                                       helperText={selectionError}
                            />
                        )}
                        loading={isLoadingProductList}
                        loadingText={"Sedang memuat data produk..."}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        required
                        id="product-quantity"
                        name="quantity"
                        label="Qty"
                        fullWidth
                        variant="standard"
                        value={productQuantity}
                        error={!!quantityError}
                        helperText={quantityError}
                        onChange={(event) =>
                            onQuantityChange(event.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <IconButton
                        aria-label="add" size="large"
                        onClick={() => addProductRequest(selectionValue)}
                    >
                        <AddCircleIcon fontSize={"large"} color={"primary"}/>
                    </IconButton>
                </Grid>
                {totalQuotaLeft !== null && <Grid item xs={12} sx={{color: "#ED8240"}}>
                    <Typography variant="subtitle1">
                        {`Sisa kuota produk anda ${totalQuotaLeft} produk`}
                    </Typography>
                </Grid>}
            </Grid>
            <Divider/>
            <Typography variant="subtitle1" sx={{pt: 2 }}>
                Permintaan Produk
            </Typography>
            {
                productRequestByEmployee.length < 1 &&
                <Typography variant="body1" align="left" sx={{fontStyle: "italic", fontSize: 12}}>
                    Anda belum menambahkan permintaan produk. <br/>
                    Silahkan pilih produk pada menu di atas, masukkan jumlah produk dan tekan tombol tambah.
                </Typography>
            }
            <List sx={{width: '100%'}}>
                {
                    productRequestByEmployee.map(product => (
                        <ListItem
                            key={product.SKUNumber}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete-product"
                                            onClick={() => deleteProductRequest(product)}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            }
                        >
                            <ListItemText id={`label-${product.productName}`} primary={product.productName}
                                          secondary={`${product.quantity} pcs`}
                            />
                        </ListItem>
                    ))
                }
            </List>
            <Dialog
                open={openConfirmationDialog}
                keepMounted
                onClose={() => setConfirmationDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Penambahan Produk"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Produk yang anda pilih sudah ada dalam daftar<br/>
                        Tekan <b>"Tambahkan"</b> apabila anda ingin melakukan penambahan jumlah.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmationDialog(false)}>Batal</Button>
                    <Button onClick={() => {
                        addProductRequest(selectionValue, true)
                    }}>Tambahkan</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default ProductSelectionForm