import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const RequestConfirmationDialog = ({isOpen, onClose, onSubmit, isProcessing}) => {
    return(
        <Dialog
            open={isOpen}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Permintaan Produk"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Apakah anda yakin ingin melakukan pemesanan produk?
                </DialogContentText>
                <p style={{fontStyle: 'italic', fontSize: 11}}>
                    *)Anda tidak dapat melakukan perubahan pada form setelah melakukan konfirmasi.
                </p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Batal</Button>
                <Button onClick={onSubmit} disabled={isProcessing}>Pesan Produk</Button>
            </DialogActions>
        </Dialog>
    )
}

export default RequestConfirmationDialog;