const INITIAL_STATE = {
    nip:'',
    nipError: null,
    email:'',
    emailError: null,
    passwordError: null,
    employeeDetail:{},
    productRequestByEmployee: [],
    selectionError: '',
    quantityError: '',
    pickUpPlace: '',
    pickUpPlaceError: null,
    employeeStatus:{},
    totalQuotaLeft: null,
    productsRequested: []
}

export const EMPLOYEE_DETAIL_UPDATE = 'EMPLOYEE_DETAIL_UPDATE';
export const PRODUCT_REQUEST_UPDATE = 'PRODUCT_REQUEST_UPDATE';
export const PRODUCT_REQUEST_ERROR = 'PRODUCT_REQUEST_ERROR';

export const employeeRequestReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EMPLOYEE_DETAIL_UPDATE:
        case PRODUCT_REQUEST_ERROR:
            return {...state, ...action.payload}
        case PRODUCT_REQUEST_UPDATE:
            return {...state, productRequestByEmployee: action.payload}
        default:
            return state
    }
}