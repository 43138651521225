import {Box, Card, CardContent, Container, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {EMPLOYEE_DETAIL_UPDATE} from "../../reducers/employee-request.reducer";

const employeeStatusList = [
    {
        key: "EMPLOYEE",
        title: "Pegawai Tetap / FLMT",
        dataSource: "Data Karyawan",
        orderData: "Pengambilan produk karyawan",
        productQuota: 6
    },
    {
        key: "WIP",
        title: "Wonderkid Internship Program (WIP)",
        dataSource: "Data WIP",
        orderData: "Order WIP",
        productQuota: 3
    },
    {
        key: "FLIP",
        title: "Future League Internship Program (FLIP)",
        dataSource: "Data FLIP",
        orderData: "Order FLIP",
        productQuota: 3
    },
    {key: "SECURITY", title: "Security", dataSource: "Data Security", orderData: "Order Security", productQuota: 3},
    {key: "OB/OG", title: "OB/OG", dataSource: "Data OB OG", orderData: "Order OB OG", productQuota: 3},
    {key: "BA", title: "Beauty Advisor (BA)", dataSource: "Data BA", orderData: "Order BA", productQuota: 1},
    {key: "FREELANCE", title: "Freelance", dataSource: "Data Freelance", orderData: "Order Freelance", productQuota: 1},
    {key: "PKPP", title: "PKPP", dataSource: "Data PKPP", orderData: "Order PKPP", productQuota: 3}
]

const EmployeeStatusComponent = () => {
    const {employeeStatus} = useSelector(state => state.employeeRequestReducer)
    const dispatch = useDispatch();

    const onSelectEmployeeStatus = (employeeStatus) => {
        dispatch({
            type: EMPLOYEE_DETAIL_UPDATE,
            payload: {employeeStatus, nip: '', email: '', employeeDetail: {}, productRequestByEmployee: []}
        })
    }

    const backgroundColor = (key) => {
        return !!employeeStatus.key && key === employeeStatus.key ? "#FFF5E1" : "#FFD39A"
    }

    return (
        <Container sx={{backgroundColor: '#F1F1F1'}}>
            <Typography variant="subtitle1" sx={{pt: 2, pb: 2}}>
                Silahkan pilih posisi anda.
            </Typography>
            <Box sx={{pb: 2}}>
                {
                    employeeStatusList.map((item) => {
                        return (
                            <Card
                                key={item.key}
                                sx={{minWidth: 200, marginY: 1, backgroundColor: backgroundColor(item.key)}}
                                onClick={() => onSelectEmployeeStatus(item)}
                            >
                                <CardContent>
                                    <Typography variant="subtitle2">
                                        {item.title}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )
                    })
                }
            </Box>
        </Container>
    )
}

export default EmployeeStatusComponent;