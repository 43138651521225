import * as React from 'react';
import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {
    Box,
    Button,
    Container,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {EMPLOYEE_DETAIL_UPDATE} from "../../reducers/employee-request.reducer";
import {getPersoneelDetailApi, getEmployeeDetailApi} from "../../api/employee-detail.api";
import validate from "../../ui-util/validation-wrapper";

const EmployeeDetailForm = () => {
    const {
        nip, nipError, employeeDetail, passwordError, employeeStatus, email, emailError
    } = useSelector(state => state.employeeRequestReducer)
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const employeeName = employeeDetail['Nama Pegawai']

    const onChangeText = (fieldName, value) => {
        dispatch({type: EMPLOYEE_DETAIL_UPDATE, payload: {[fieldName]: value}})
    }

    const getEmployeeDetail = () => {
        setIsLoading(true)

        let nipError = validate('nip', nip, 'employeeDetailValidation')
        let emailError = validate('email', email, 'employeeDetailValidation')
        let passwordError = null;
        let accessPassed = true;

        if (accessPassed) {
            switch (employeeStatus.key) {
                case 'EMPLOYEE':
                    if (!nipError) {
                        getEmployeeDetailApi(`1010.${nip}`)
                            .then(employeeDetail => {
                                setIsLoading(false)
                                if (!!employeeDetail && password === employeeDetail.Key) {
                                    dispatch({
                                        type: EMPLOYEE_DETAIL_UPDATE,
                                        payload: {
                                            employeeDetail,
                                            nipError: null,
                                            passwordError: null,
                                        }
                                    })
                                } else {
                                    let payload = {nipError: "NIP tidak ditemukan"}
                                    if (password !== employeeDetail.Key) {
                                        payload = {passwordError: "Password tidak valid"}
                                    }
                                    dispatch({
                                        type: EMPLOYEE_DETAIL_UPDATE,
                                        payload
                                    })
                                }
                            })
                            .catch(() => {
                                setIsLoading(false)
                                alert("Gagal mendapatkan data")
                            })
                    } else {
                        errorGetEmployee(nipError, passwordError)
                    }
                    break;
                default:
                    if (!emailError) {
                        getPersoneelDetailApi(email, employeeStatus.dataSource)
                            .then(employeeDetail => {
                                setIsLoading(false)
                                if (!!employeeDetail && password === employeeDetail.Key) {
                                    dispatch({
                                        type: EMPLOYEE_DETAIL_UPDATE,
                                        payload: {employeeDetail, emailError: null, passwordError: null}
                                    })
                                } else {
                                    let payload = {emailError: "Email tidak ditemukan"}
                                    if (password !== employeeDetail.Key) {
                                        payload = {passwordError: "Password tidak valid"}
                                    }
                                    dispatch({
                                        type: EMPLOYEE_DETAIL_UPDATE,
                                        payload
                                    })
                                }
                            })
                            .catch(() => {
                                setIsLoading(false)
                                alert("Gagal mendapatkan data")
                            })
                    } else {
                        errorGetEmployee(nipError, passwordError, emailError)
                    }
            }
        } else {
            passwordError = "Masukkan password akses"
            errorGetEmployee(nipError, passwordError, emailError)
        }
    }

    const errorGetEmployee = (nipError, passwordError, emailError) => {
        setIsLoading(false)
        passwordError = "Masukkan password akses"
        dispatch({
            type: EMPLOYEE_DETAIL_UPDATE,
            payload: {
                nipError,
                passwordError,
                emailError,
                employeeDetail: {}
            }
        })
    }

    const showTextFieldIdentity = () => {
        switch (employeeStatus.key) {
            case "EMPLOYEE":
                return (
                    <TextField
                        variant="standard"
                        fullWidth
                        name="nip"
                        label="NIP"
                        id="nip"
                        required
                        InputProps={{
                            startAdornment: <InputAdornment position="start">1010.</InputAdornment>,
                        }}
                        value={nip}
                        error={!!nipError}
                        helperText={nipError}
                        onChange={(event) => {
                            onChangeText('nip', event.target.value)
                        }}
                    />
                )
            default:
                return (
                    <TextField
                        variant="standard"
                        fullWidth
                        name="email"
                        label="Email"
                        id="email"
                        required
                        value={email}
                        error={!!emailError}
                        helperText={emailError}
                        onChange={(event) => {
                            onChangeText('email', event.target.value)
                        }}
                    />
                )
        }
    }

    return (
        <Container sx={{backgroundColor: '#F1F1F1'}}>
            <Typography variant="subtitle1" sx={{pt: 2, pb: 2}}>
                Silahkan masukkan data anda.
            </Typography>
            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={12}>
                    {showTextFieldIdentity()}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        name="password"
                        label="Password"
                        id="password"
                        required
                        value={password}
                        error={!!passwordError}
                        helperText={passwordError}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                    />
                </Grid>
                <Grid container sx={{mt: 3}} direction='row' alignItems='center' justifyContent='center'>
                    <Grid item xs={6}>
                        <Button
                            variant="contained" size="small"
                            color="success" fullWidth
                            onClick={getEmployeeDetail}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Pencarian...' : 'Konfirmasi'}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <p style={{fontSize: 10}}>*) Password sudah dikirimkan ke email anda yang terdaftar di
                        perusahaan. </p>
                    <p style={{fontSize: 10}}><b>**) Subject Email: AVO Product Request - Password</b></p>
                </Grid>
            </Grid>

            {!!employeeName &&
                <Box sx={{pt: 3, pb: 3}}>
                    <Typography variant="subtitle1" sx={{textAlign: 'center', pb: 1}}>
                        Data Pegawai
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="employee-detail">
                            <TableBody>
                                {Object.keys(employeeDetail).map((key) => {
                                        if (key === 'Key' || key === 'isEmailPasswordSend') {
                                            return false
                                        } else {
                                            return (
                                                <TableRow
                                                    key={key}
                                                >
                                                    <TableCell sx={{maxWidth: 100, backgroundColor: "#FFD39A"}}
                                                               component="th"
                                                               scope="row">
                                                        {key}
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ backgroundColor: "#FFF5E1"}}>
                                                        {employeeDetail[key]}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            }
        </Container>
    )
        ;
}

export default EmployeeDetailForm;