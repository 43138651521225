import './App.css';
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import reducers from "./reducers";
import InformationPage from "./pages/information/information.page";

function App() {
  return (
    <Provider store={createStore(reducers, {}, applyMiddleware(thunk))}>
        <InformationPage/>
    </Provider>
  );
}

export default App;
