import React, {useState} from 'react';
import {AppBar, Button, Container, MobileStepper, Paper, Toolbar, Typography, Grid} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import EmployeeDetailForm from "./employee-detail.form";
import ProductSelectionForm from "./product-selection.form";
import {useDispatch, useSelector} from "react-redux";
import validate from "../../ui-util/validation-wrapper";
import {EMPLOYEE_DETAIL_UPDATE, PRODUCT_REQUEST_ERROR} from "../../reducers/employee-request.reducer";
import RequestSummary from "./request-summary.component";
import RequestConfirmationDialog from "./request-confirmation.dialog";
import {setOrderApi, setEmployeeOrderApi} from "../../api/request-product.api";
import _ from 'lodash';
import {randomNumber} from "../../ui-util/general-variable";
import EmployeeStatusComponent from "./employee-status.component";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import moment from "moment";

const steps = ['Posisi Pegawai', 'Data Pegawai', 'Pesan Produk', 'Rincian Permintaan', 'Permintaan Produk Selesai'];

function getStepContent(step) {
    switch (step) {
        case 0:
            return <EmployeeStatusComponent/>;
        case 1:
            return <EmployeeDetailForm/>;
        case 2:
            return <ProductSelectionForm/>;
        case 3:
            return <RequestSummary/>;
        case 4:
            return (
                <Container sx={{backgroundColor: '#F1F1F1', pb: 2, pt: 2}}>
                    <Typography variant="subtitle1">
                        Permintaan produk berhasil dilakukan.<br/>
                        Produk dapat diambil pada waktu dan lokasi yang sudah ditentukan.
                        Keterangan lebih rinci tedapat dalam SOP Fasilitas Produk Karyawan yang sudah dikeluarkan oleh
                        P&C.
                    </Typography>
                </Container>
            )
        default:
            throw new Error('Unknown step');
    }
}

const theme = createTheme()

const RequestFormPage = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const {
        employeeDetail, productRequestByEmployee,
        pickUpPlace, employeeStatus, totalQuotaLeft
    } = useSelector(state => state.employeeRequestReducer);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const dispatch = useDispatch();

    const handleNext = () => {
        formValidation()
    };

    function formValidation() {
        switch (activeStep) {
            case 0:
                const today = moment().date();
                // const today = 16
                if (!!employeeStatus.key) {
                    if (employeeStatus.key === "BA" && (today < 15 || today > 20)) {
                        alert("BA hanya dapat melakukan permintaan pada tanggal 15-20 setiap bulan.")
                        return;
                    } else {
                        setActiveStep(activeStep + 1)
                    }
                }
                break
            case 1:
                let employeeNameError = validate('employeeName', employeeDetail["Nama Pegawai"], 'employeeDetailValidation')
                dispatch({type: EMPLOYEE_DETAIL_UPDATE, payload: {employeeNameError}})
                if (!employeeNameError) setActiveStep(activeStep + 1)
                break
            case 2:
                const totalProductToRequest = _.sumBy(productRequestByEmployee, product => Number(product.quantity))
                const isProductQuotaValid = totalProductToRequest <= totalQuotaLeft
                if (productRequestByEmployee.length > 0 && isProductQuotaValid) {
                    setActiveStep(activeStep + 1)
                } else {
                    if (!isProductQuotaValid) {
                        alert("Jumlah permintaan melebihi kuota bulanan. Sisa kuota anda bulan ini "
                            + totalQuotaLeft + " produk")
                    } else {
                        dispatch({
                            type: PRODUCT_REQUEST_ERROR,
                            payload: {selectionError: "Pilih salah satu produk", quantityError: "Max. 6"}
                        })
                    }
                }
                break
            case 3:
                if (!!pickUpPlace || employeeStatus.key === "BA") {
                    setOpenConfirmationDialog(true)
                } else {
                    dispatch({
                        type: EMPLOYEE_DETAIL_UPDATE,
                        payload: {pickUpPlaceError: "Pilih salah satu tempat pengambilan"}
                    })
                }
                break
            default:
                return true
        }
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const submitProductRequest = () => {
        setIsProcessing(true)
        employeeRequest()
    }

    const employeeRequest = () => {
        let productRequestObj = {}
        let productKeys = _.keys(productRequestByEmployee[0])
        const requestNumber = `AVO-${randomNumber(5)}`
        const date = new Date()
        const requestDate = `${date.getMonth() + 1} ${date.getDate()} ${date.getFullYear()}`

        productRequestByEmployee.forEach((item, index) => {
            productKeys.forEach(key => {
                let columnKey = '';
                switch (key) {
                    case 'gs1Barcode':
                        columnKey = `Gs1Barcode${index + 1}`
                        break
                    case 'productName':
                        columnKey = `NamaProduk${index + 1}`
                        break
                    case `quantity` :
                        columnKey = `QuantityProduk${index + 1}`
                        break
                    default:
                        return false
                }

                productRequestObj = {
                    ...productRequestObj,
                    [columnKey]: item[key]
                }
            })
        })

        if (employeeStatus.key === 'EMPLOYEE') {
            placeEmployeeOrder(requestNumber, requestDate)
        } else {
            placeOrder(requestNumber, requestDate)
        }
    }

    const constructProductRequestList = (requestNumber, requestDate) => {
        let productRequestArray = []
        let pickUpLocation = pickUpPlace

        if (employeeStatus.key === 'BA') {
            pickUpLocation = employeeDetail["Alamat Pengiriman"]
        }

        productRequestByEmployee.forEach(item => {
            const productRequestObj = {
                TanggalPengajuan: requestDate,
                NomorPengajuan: requestNumber,
                NamaKaryawan: employeeDetail["Nama Pegawai"],
                Email: employeeDetail.Email,
                Divisi: employeeDetail.Divisi,
                TempatPengambilan: pickUpLocation,
                GS1Barcode: item.gs1Barcode,
                NamaProduk: item.productName,
                JumlahProduk: item.quantity
            }
            productRequestArray.push(productRequestObj)
        })

        return productRequestArray
    }

    const placeEmployeeOrder = (requestNumber, requestDate) => {
        let productRequestArray = constructProductRequestList(requestNumber, requestDate);

        setEmployeeOrderApi(productRequestArray)
            .then(() => {
                successOrderHandler()
            })
            .catch(err => {
                alert(err.detail)
            })
    }

    const placeOrder = (requestNumber, requestDate) => {
        let productRequestArray = constructProductRequestList(requestNumber, requestDate)

        setOrderApi(productRequestArray, employeeStatus.orderData)
            .then(() => {
                successOrderHandler()
            })
            .catch(err => {
                alert(err.detail)
            })
    }

    const successOrderHandler = () => {
        setOpenConfirmationDialog(false)
        dispatch({
            type: EMPLOYEE_DETAIL_UPDATE,
            payload: {employeeStatus: {}, employeeDetail: {}}
        })
        setIsProcessing(false)
        setActiveStep(activeStep + 1)
    }

    return (
        <ThemeProvider theme={theme}>
            <AppBar
                position="absolute"
                elevation={0}
                sx={{
                    backgroundColor: '#F1F1F1',
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
                <Toolbar>
                    <Typography variant="h6" color="black" noWrap>
                        PT. AVO Innovation Technology
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="sm" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                    <Typography component="h1" variant="h5" align="center" sx={{pb: 5}}>
                        Pemesanan Produk
                    </Typography>
                    <Paper
                        square
                        elevation={0}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 50,
                            pl: 2,
                            bgcolor: '#FFF5E1',
                        }}
                    >
                        <Typography>{steps[activeStep]}</Typography>
                    </Paper>
                    <React.Fragment>
                        <React.Fragment>
                            {getStepContent(activeStep)}
                        </React.Fragment>
                    </React.Fragment>
                    <MobileStepper
                        sx={{backgroundColor: "#FFF5E1"}}
                        variant="text"
                        steps={steps.length}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={!employeeStatus.key}
                            >
                                {activeStep === steps.length - 2 ? 'Place order' : 'Next'}
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft/>
                                ) : (
                                    <KeyboardArrowRight/>
                                )}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack}
                                    disabled={activeStep === 0 || activeStep === steps.length - 1}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight/>
                                ) : (
                                    <KeyboardArrowLeft/>
                                )}
                                Back
                            </Button>
                        }
                    />
                </Paper>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Typography variant="caption">
                            V1.0.1 - 30112024 1122
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <RequestConfirmationDialog
                isOpen={openConfirmationDialog}
                onClose={() => setOpenConfirmationDialog(false)}
                onSubmit={submitProductRequest}
                isProcessing={isProcessing}
            />
        </ThemeProvider>
    )
}

export default RequestFormPage;
