const getProductList = () => {
    return new Promise((resolve, reject) => {
        fetch("https://sheet.best/api/sheets/f7661507-272f-4ed9-8fdf-c6d1a9d92551/tabs/Product V1")
            .then((r) => r.json())
            .then((data) => {
                if (!data.detail) {
                    resolve(data)
                } else {
                    reject(data.detail)
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}
const setOrderApi = (bodyRequest, orderData) => {
    return new Promise((resolve, reject) => {
        fetch(`https://sheet.best/api/sheets/f254bea6-629e-47de-9e42-2bc3fb772256/tabs/${orderData}`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": "OFUQS-b@Nn6YjLBf3KKr1bnHe07BbeZ$MKRk!sOAEZ$DyMRLN5G3XT@OSeb6ChRR"
                },
                body: JSON.stringify(bodyRequest),
            })
            .then((r) => r.json())
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const getEmployeeOrder = (orderData, email, startDate) => {
    return new Promise((resolve, reject) => {
        fetch(`https://sheet.best/api/sheets/f254bea6-629e-47de-9e42-2bc3fb772256/tabs/${orderData}/
        query?Email=__eq(${email})&TanggalPengajuan=__gte(${startDate})`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": "OFUQS-b@Nn6YjLBf3KKr1bnHe07BbeZ$MKRk!sOAEZ$DyMRLN5G3XT@OSeb6ChRR"
                },
            }
        )
            .then(r => r.json())
            .then(data => resolve(data))
            .catch(err=> reject(err))
    })
}

export {getProductList, setOrderApi, getEmployeeOrder}