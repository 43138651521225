import React from 'react';
import {
    Container, Divider,
    FormControl, FormHelperText,
    InputLabel, MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import {EMPLOYEE_DETAIL_UPDATE} from "../../reducers/employee-request.reducer";

const RequestSummary = () => {
    const {
        employeeDetail, productRequestByEmployee, employeeStatus,
        pickUpPlace, pickUpPlaceError
    } = useSelector((state) => state.employeeRequestReducer)
    const dispatch = useDispatch();

    const showPickUpPlace = () => {
        switch (employeeStatus.key) {
            case 'BA':
                return(
                    <Grid item xs={12} sx={{pt: 1}}>
                        <Typography variant='subtitle1'>
                            {employeeDetail["Alamat Pengiriman"]}
                        </Typography>
                    </Grid>
                )
            default:
                return (
                    <Grid item xs={12} sx={{pt: 1}}>
                        <FormControl variant="standard" fullWidth required>
                            <InputLabel id="pickUpPlace">Tempat Pengambilan</InputLabel>
                            <Select
                                labelId="pickUpPlace"
                                id="pickUpPlace"
                                name="pickUpPlace"
                                label="Pilih tempat pengambilan"
                                value={pickUpPlace}
                                error={!!pickUpPlaceError}
                                onChange={(event) => {
                                    dispatch({
                                        type: EMPLOYEE_DETAIL_UPDATE,
                                        payload: {pickUpPlace: event.target.value}
                                    })
                                }}
                            >
                                <MenuItem value={"HQ Monjali"}>Kantor Pusat Monjali</MenuItem>
                                <MenuItem value={"WH Demak Ijo"}>Warehouse Demak Ijo</MenuItem>
                            </Select>
                            <FormHelperText error>{pickUpPlaceError}</FormHelperText>
                        </FormControl>
                    </Grid>
                )
        }
    }

    return (
        <Container sx={{backgroundColor: '#F1F1F1', pb: 2, pt: 2}}>
            <Typography variant='subtitle1'>Data Pegawai</Typography>
            <Divider/>
            <Typography variant='body2'>
                {employeeDetail["Nama Pegawai"]} <br/>
                {employeeDetail["Email"]} <br/>
                {employeeDetail["Divisi"]}
            </Typography>
            <br/>
            <Typography variant='subtitle1'>Pesanan Produk</Typography>
            <Divider/>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nama Produk</TableCell>
                        <TableCell align='right'>Qty.</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {productRequestByEmployee.map((row) => (
                        <TableRow
                            key={row.gs1Barcode}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {row.productName}
                            </TableCell>
                            <TableCell align="right">{row.quantity}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <br/>
            <Typography variant='subtitle1'>Tempat Pengambilan Produk</Typography>
            <Divider/>
            {showPickUpPlace()}
        </Container>
    )
}

export default RequestSummary;