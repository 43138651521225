export const getEmployeeDetailApi = (nip) => {
    return new Promise ((resolve, reject) => {
        fetch(`https://sheet.best/api/sheets/43329b93-436d-4a47-9bed-a8a77dc5b17f/NIP/${nip}`)
            .then((r) => r.json())
            .then((data) => {
                if (!data.detail) {
                    resolve(data[0])
                } else {
                    reject(data.detail)
                }
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const getPersoneelDetailApi = (email, dataSource) => {
    return new Promise ((resolve, reject) => {
        fetch(`https://sheet.best/api/sheets/43329b93-436d-4a47-9bed-a8a77dc5b17f/tabs/${dataSource}/Email/${email}`)
            .then((r) => r.json())
            .then((data) => {
                if (!data.detail) {
                    resolve(data[0])
                } else {
                    reject(data.detail)
                }
            })
            .catch(err => {
                reject(err)
            })
    })
}