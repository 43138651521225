import validateJs from 'validate.js';
import {validation} from './validation';

export default function validate(fieldName, value, rule) {
    let formValue = {};
    formValue[fieldName] = value;

    let formFields = {};
    formFields[fieldName] = validation[rule][fieldName];

    const result = validateJs(formValue, formFields);

    if(!!result){
        return result[fieldName][0]
    }
}
